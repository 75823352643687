import { BlogList } from "@/components/Blog/BlogList/BlogList";
import { Hero } from "@/components/home/Hero/Hero";
import { Container } from "@/components/Shared/Container/Container";
import { Stack } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import { graphql } from "gatsby";
import React from "react";
import hero from "../../assets/hero.png";
import Layout from "../../components/Shared/Layout/Layout";
import { SEO } from "../../components/Shared/SEO/SEO";

export const query = graphql`
  query getBlogs {
    allStrapiBlog {
      nodes {
        content
        slug
        title
        strapiId
        published_at
        image {
          localFile {
            publicURL
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        category {
          name
          slug
        }
        author {
          email
          firstName
          lastName
        }
        description
      }
    }
  }
`;

const BlogPage = (props: any) => {
  return (
    <Layout>
      <SEO description="This is an index page" />
      <Hero
        imageUrl={hero}
        title={"Blog"}
        cta={{
          text: "Predpôrodné kurzy",
          href: "/#predporodne-kurzy"
        }}
        description={
          <>
            Pre rodičov je potrebné poznať a pripraviť sa na jednotlivé
            situácie, čo v konečnom <br className="hidden xl:flex" />
            dôsledku{" "}
            <chakra.span color="primary">
              výrazne napomáha úspešnému priebehu gravidity
            </chakra.span>
            , ako aj pôrodu.
          </>
        }
      />
      <Container>
        <Stack spacing={{ base: 14, md: 20 }} py={{ base: 14, md: 20 }}>
          <BlogList />
        </Stack>
      </Container>
    </Layout>
  );
};

export default BlogPage;
