import { Box, BoxProps, Stack } from "@chakra-ui/react";
import React from "react";
import { BlogCard } from "../BlogCard/BlogCard";
import { useBlogs } from "./hooks/useBlogs";

export interface BlogListProps extends BoxProps {}

export const BlogList = (props: BlogListProps) => {
  const { ...restOfProps } = props;

  const blogs = useBlogs();

  return (
    <Box {...restOfProps}>
      <Stack spacing={{ base: 10, md: 20 }} px={{ xl: 20 }}>
        {blogs.map((blog: any) => (
          <BlogCard
            title={blog.title}
            description={blog.description}
            image={blog.image}
            cta={{ text: "Čítať článok", href: `/blog/${blog.slug}` }}
          />
        ))}
      </Stack>
    </Box>
  );
};
