import { Button } from "@/components/Shared/Button/Button";
import {
  Box,
  BoxProps,
  Flex,
  GridItem,
  SimpleGrid,
  Stack
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export interface BlogCardProps extends BoxProps {
  title: string;
  image: any;
  description: string;
  cta: {
    text: string;
    href: string;
  };
}

export const BlogCard = (props: BlogCardProps) => {
  const { title, description, image, cta, ...restOfProps } = props;
  const finalImage = getImage(image.localFile);

  return (
    <Box {...restOfProps}>
      <SimpleGrid gridGap={8} columns={12} h={{ base: "auto", md: 52 }}>
        <GridItem
          boxShadow="lg"
          colSpan={{ base: 12, md: 4 }}
          overflow="hidden"
          w="full"
          h={{ base: 64, md: "full" }}
        >
          <GatsbyImage alt={`${title}`} image={finalImage!} />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 8 }} h="full">
          <Flex flexDir="column" justifyContent="space-between" h="full">
            <Stack spacing={4}>
              <Box fontSize="xl" fontWeight={700}>
                {title}
              </Box>
              <Box>{description}</Box>
            </Stack>
            <Flex
              justifyContent={{ base: "center", md: "flex-end" }}
              pt={{ base: 3, md: 0 }}
            >
              <Link to={cta.href}>
                <Button>{cta.text}</Button>
              </Link>
            </Flex>
          </Flex>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
