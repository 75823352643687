import { graphql, useStaticQuery } from "gatsby";

export const useBlogs = (): any => {
  const data = useStaticQuery(graphql`
    query useBlogs {
      allStrapiBlog {
        nodes {
          content
          slug
          title
          strapiId
          published_at
          category {
            name
            slug
          }
          author {
            email
            firstName
            lastName
          }
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 280
                  quality: 90
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    }
  `);

  return data.allStrapiBlog.nodes;
};
